import React from "react";

import DesignGallery from "../../components/DesignGallery/DesignGallery";

//import classes from "./PrivacyPolicyPage.module.scss";

const DesignPage = (props) => {
  return (
    <div>
      <DesignGallery {...props}></DesignGallery>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      ></div>
    </div>
  );
};

export default DesignPage;
