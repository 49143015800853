import React, { useState } from "react";

import classes from "./ContactSection.module.scss";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLinkedin,
  faInstagramSquare,
} from "@fortawesome/free-brands-svg-icons";
import { faEnvelopeSquare } from "@fortawesome/free-solid-svg-icons";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import { useHistory } from "react-router-dom";

const ContactSection = (props) => {
  const [showContactForm, setShowContactForm] = useState(false);
  const [showLinkedinMessage, setShowLinkedinMessage] = useState(false);
  const [showIntaMessage, setShowInstaMessage] = useState(false);
  const [validated, setValidated] = useState(false);
  const [hadSubmissionError, setHadSubmissionError] = useState(false);

  const history = useHistory();

  const handleSubmitContactForm = async (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      console.log("not valid");
    } else {
      // console.log("valid");
      // console.log(("email: ", event.target.elements.senderEmail.value));
      // console.log(("message: ", event.target.elements.senderMessage.value));
      console.log("sending email!");
      const body = {
        sender: event.target.elements.senderEmail.value,
        message: event.target.elements.senderMessage.value,
      };
      try {
        //history.replace("/#/#contact");
        console.log("HEREEEEEEEE");
        await fetch(
          "https://iaqm8p13o1.execute-api.us-east-1.amazonaws.com/prod/contact",
          {
            method: "POST",
            body: JSON.stringify(body),
            headers: {
              "Content-Type": "application/json",
              "x-api-key": "b97zS923ma9qjKB5t5xxh4syTg0rLraw8W1e9eOI",
            },
          }
        );

        //setTimeout(async () => {
        await setValidated(false);
        await setHadSubmissionError(false);
        await setShowContactForm(false);

        // }, 200);
      } catch (err) {
        //console.log("caught error sending message: ", err);
        await setHadSubmissionError(true);
      }
    }

    await setValidated(true);
    return false;
  };

  const emailModal = (
    <Modal
      show={showContactForm}
      onHide={() => {
        setShowContactForm(false);
        setValidated(false);
        setHadSubmissionError(false);
      }}
      backdrop="static"
      keyboard={false}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Contact Us</Modal.Title>
      </Modal.Header>

      {/* I will not close if you click outside me. Don't even try to press
    escape key. */}
      <Form noValidate validated={validated} onSubmit={handleSubmitContactForm}>
        <Modal.Body>
          <Form.Group controlId="senderEmail">
            <Form.Label>Email address</Form.Label>
            <Form.Control
              type="email"
              placeholder="name@example.com"
              required
            />
            <Form.Control.Feedback type="invalid">
              Please provide a valid email address we can reach you at.
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="senderMessage">
            <Form.Label>Your message</Form.Label>
            <Form.Control as="textarea" rows="3" required />
            <Form.Control.Feedback type="invalid">
              Please a provide a message.
            </Form.Control.Feedback>
          </Form.Group>
          {hadSubmissionError && (
            <Alert variant="danger">
              Sorry, we're having trouble sending your message! Please try again
              later.
            </Alert>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setShowContactForm(false);
              setValidated(false);
              setHadSubmissionError(false);
            }}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            variant="primary"
            // onClick={() => {
            //   setShowContactForm(false);
            // }}
          >
            Send
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );

  const linkedinModal = (
    <Modal
      show={showLinkedinMessage}
      onHide={() => {
        setShowLinkedinMessage(false);
      }}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Check back soon!</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Our LinkedIn is currently under construction! The best way to contact us
        is to use the "mail" icon in the Contact section.
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="primary"
          onClick={() => {
            setShowLinkedinMessage(false);
          }}
        >
          Ok
        </Button>
      </Modal.Footer>
    </Modal>
  );

  const instaModal = (
    <Modal
      show={showIntaMessage}
      onHide={() => {
        setShowInstaMessage(false);
      }}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Check back soon!</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Our Instagram is currently under construction! The best way to contact
        us is to use the "mail" icon in the Contact section.
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="primary"
          onClick={() => {
            setShowInstaMessage(false);
          }}
        >
          Ok
        </Button>
      </Modal.Footer>
    </Modal>
  );

  return (
    <React.Fragment>
      {emailModal}
      {linkedinModal}
      {instaModal}
      <div id={props.id} className="container" style={{ paddingTop: "80px" }}>
        <div className="row">
          <div className="col-lg-8 mx-auto text-center">
            <h2
              className={`section-heading ${classes.SectionTitle}`}
              style={{ margintop: "50px" }}
            >
              Contact
            </h2>
            <hr className="my-4" style={{ maxWidth: 350 }} />
          </div>
        </div>
        <div className="row" style={{ marginBottom: "5%" }}>
          <div
            className="ml-auto mr-auto text-center"
            style={{ textAlign: "center" }}
          >
            <span
              onClick={() => {
                setShowLinkedinMessage(true);
              }}
              className={`${classes.EmailIcon}`}
            >
              {/* <a href="https://www.linkedin.com/in/michaelkiley-hope/"> */}
              <FontAwesomeIcon
                icon={faLinkedin}
                style={{ fontSize: "3em", color: "#566E96", margin: "10px" }}
              ></FontAwesomeIcon>
              {/* </a> */}
            </span>
            <span
              onClick={() => {
                setShowContactForm(true);
                setValidated(false);
                setHadSubmissionError(false);
                //history.push("/#contact");
              }}
              className={`${classes.EmailIcon}`}
            >
              <FontAwesomeIcon
                icon={faEnvelopeSquare}
                style={{ fontSize: "3em", color: "#6C9DB4", margin: "10px" }}
              ></FontAwesomeIcon>
            </span>
            <span
              onClick={() => {
                setShowInstaMessage(true);
              }}
              className={`${classes.EmailIcon}`}
            >
              {/* <a href="https://www.linkedin.com/in/michaelkiley-hope/"> */}
              <FontAwesomeIcon
                icon={faInstagramSquare}
                style={{ fontSize: "3em", color: "#81CBD2", margin: "10px" }}
              ></FontAwesomeIcon>
              {/* </a> */}
            </span>

            <p styles={{ color: "lightgray" }} className="">
              We'd love to hear from you!
            </p>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ContactSection;
