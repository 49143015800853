import React from "react";

import Aux from "../../hoc/Aux/Aux";
import logo from "../../assets/images/logo_no_background.png";
import classes from "./Banner.module.scss";

import { Motion, spring } from "react-motion";

const banner = (props) => {
  const logoClasses = props.smallLogo
    ? `${classes.Logo} ${classes.SmallLogo}`
    : `${classes.Logo}`;

  // const WrapperElement = props.hideLogo ? (
  //   <img src={logo} className={classes.Logo}></img>
  // ) : (
  //   <Motion
  //     defaultStyle={{ positionX: 0 }}
  //     style={{
  //       scale: spring(0.2, { stiffness: 60, damping: 15 }),
  //       positionX: spring(155, { stiffness: 60, damping: 15 }),
  //     }}
  //   >
  //     {(interpolatedStyle) => (
  //       <img
  //         src={logo}
  //         className={classes.Logo}
  //         style={{
  //           transform: ` translateX( -${interpolatedStyle.positionX}% )`,
  //         }}
  //       />
  //     )}
  //   </Motion>
  // );

  const logoEl = !props.presentLogo ? (
    <img className={logoClasses} src={logo}></img>
  ) : null;

  return (
    // <header
    //   className={`masthead ${classes.CenteredLogoContainer}`}
    //   id={props.id}
    // >
    <header
      className={`masthead ${classes.CenteredLogoContainer}`}
      id={props.id}
    >
      {/* {WrapperElement} */}
      {/* <img className={logoClasses} src={logo}></img> */}

      {logoEl}
      {props.children}
    </header>
  );
};

export default banner;
