import React from "react";

import classes from "./MissionStatementSection.module.scss";

import beach from "../../assets/images/beach.png";
import Paper from "@material-ui/core/Paper";

const MissionStatementSection = (props) => {
  return (
    <div
      className="about"
      id={props.id}
      style={{ backgroundColor: "white" }}
      style={{ marginBottom: 30 }}
    >
      <div className="container">
        <div className={`row ${classes.ResponsiveRow}`}>
          <div
            className={`col-sm-12 col-lg-12 col-md-12 ${classes.ResponsiveCol}`}
            style={{
              textAlign: "center",
              paddingTop: "30px",
            }}
          >
            <div className={`${classes.SectionTitle}`}>
              Innovation{" "}
              <span style={{ fontWeight: "normal", color: "#212529" }}>
                with
              </span>
            </div>
          </div>
        </div>
        {/* <div className="row">
          <div className="col-lg-8 mx-auto text-center">
            <h2
              className="section-heading"
              style={{ paddingTop: "30px", marginTop: "50px" }}
            >
              Innovating with...
            </h2>
            <hr className="my-4" style={{ maxWidth: 400 }} />
          </div>
        </div> */}
        <div className={`row ${classes.ResponsiveRow}`}>
          <div
            className={`col-sm-12 col-lg-6 col-md-6 ${classes.ResponsiveCol}`}
            style={{}}
          >
            <div className={`${classes.CoreValueTitle}`}>- Quality -</div>
            <div style={{ marginBottom: 50 }}>
              We hold our work to the highest standard, ensuring that our
              solutions are built with efficiency, maintainability, security,
              and an excellent user experience in mind from the groundwork to
              the finishing touches.
            </div>
          </div>
          <div
            className={`col-sm-12 col-lg-6 col-md-6 ${classes.ResponsiveCol}`}
            style={{}}
          >
            <div className={`${classes.CoreValueTitle}`}>- Purpose -</div>
            <div style={{ marginBottom: 50 }}>
              We believe in the importance of service. We strive to create
              technology that will be used with purpose and we love applying our
              skills to this challenge.{" "}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MissionStatementSection;
