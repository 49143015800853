import React, { useState } from "react";

import Image from "react-bootstrap/Image";

import spanishLanding from "../../assets/images/study_squares_spanish_landing.png";
import languageHuntLogo from "../../assets/images/study_squares_spanish_logo.png";
import spanishFlavorLogo from "../../assets/images/spanish_vocab_flavor.png";
import frenchFlavorLogo from "../../assets/images/french_vocab_flavor_comingsoon.png";
import apWorldFlavorLogo from "../../assets/images/ap_world_flavor_comingsoon.png";
import satVocabFlavorLogo from "../../assets/images/sat_vocab_flavor_comingsoon.png";

//import PrivacyPolicy from "../../components/PrivacyPolicy/PrivacyPolicy"

import classes from "./StudySquaresPage.module.scss";

const selectedTopicKeys = {
  SPANISH_VOCAB: "SPANISH_VOCAB",
  FRENCH_VOCAB: "FRENCH_VOCAB",
  AP_WORLD: "AP_WORLD",
  SAT_VOCAB: "SAT_VOCAB",
};

const selectedTopicMeta = {
  SPANISH_VOCAB: {
    tintColor: "#E63946",
    displayName: "SPANISH VOCAB",
  },
  FRENCH_VOCAB: {},
  AP_WORLD: {},
  SAT_VOCAB: {},
};

const StudySquaresPage = (props) => {
  const [selectedTopic, setSelectedTopic] = useState("");

  const spanishFlavor = (
    <a
      key={1}
      onClick={() => {
        setSelectedTopic(selectedTopicKeys.SPANISH_VOCAB);
      }}
      style={{
        //width: 200,
        height: 200,
        //backgroundColor: "whitesmoke",
        // margin: 10,
        borderRadius: 6,
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
        marginBottom: 65,
        //margin: 8,
      }}
      className={`${classes.FlavorCard} col-sm-12 col-lg-3 col-md-4`}
    >
      {" "}
      <div className={classes.LogoImage}>
        {/* <img src={logo} className={"img-fluid"} /> */}
        <Image src={spanishFlavorLogo} fluid />
      </div>
    </a>
  );

  const frenchFlavor = (
    <a
      key={2}
      // onClick={() => {
      //   setSelectedTopic(selectedTopicKeys.FRENCH_VOCAB);
      // }}
      style={{
        //width: 200,
        height: 200,
        //backgroundColor: "whitesmoke",
        // margin: 10,
        borderRadius: 6,
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
        marginBottom: 65,
        //margin: 8,
      }}
      className={`${classes.FlavorCardComingSoon} col-sm-12 col-lg-3 col-md-4`}
    >
      {" "}
      <div className={classes.LogoImage}>
        {/* <img src={logo} className={"img-fluid"} /> */}
        <Image src={frenchFlavorLogo} fluid />
      </div>
    </a>
  );

  const apWorldFlavor = (
    <a
      key={3}
      // onClick={() => {
      //   setSelectedTopic(selectedTopicKeys.AP_WORLD);
      // }}
      style={{
        //width: 200,
        height: 200,
        //backgroundColor: "whitesmoke",
        // margin: 10,
        borderRadius: 6,
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
        marginBottom: 65,
        //margin: 8,
      }}
      className={`${classes.FlavorCardComingSoon} col-sm-12 col-lg-3 col-md-4`}
    >
      {" "}
      <div className={classes.LogoImage}>
        {/* <img src={logo} className={"img-fluid"} /> */}
        <Image src={apWorldFlavorLogo} fluid />
      </div>
    </a>
  );

  const satVocabFlavor = (
    <a
      key={4}
      // onClick={() => {
      //   setSelectedTopic(selectedTopicKeys.SAT_VOCAB);
      // }}
      style={{
        //width: 200,
        height: 200,
        //backgroundColor: "whitesmoke",
        // margin: 10,
        borderRadius: 6,
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
        marginBottom: 65,
        //margin: 8,
      }}
      className={`${classes.FlavorCardComingSoon} col-sm-12 col-lg-3 col-md-4`}
    >
      {" "}
      <div className={classes.LogoImage}>
        {/* <img src={logo} className={"img-fluid"} /> */}
        <Image src={satVocabFlavorLogo} fluid />
      </div>
    </a>
  );

  const flavors = [spanishFlavor, frenchFlavor, apWorldFlavor, satVocabFlavor];

  const titleInsertionView = selectedTopic ? (
    <span
      style={{
        fontWeight: "bold",
        color: selectedTopicMeta[selectedTopic].tintColor,
        fontSize: "120%",
        fontFamily: "'Fugaz One', cursive",
      }}
    >
      {selectedTopicMeta[selectedTopic].displayName}
    </span>
  ) : null;

  const topicSelectionView = (
    <>
      <div
        style={{
          width: "100%",
          alignItems: "flex-start",
          paddingLeft: 20,
          marginTop: 40,
          //fontWeight: "bold",
          fontSize: 25,
          fontFamily: "'Bowlby One SC', cursive",
          color: "#457B9D",
        }}
      >
        Select your topic:{" "}
      </div>
      <div
        style={{
          alignSelf: "center",
          justifyContent: "center",
          display: "flex",
          //paddingTop: 5,
          width: "100%",
        }}
        id={"100"}
      >
        <div
          style={{
            fontWeight: "bold",
            fontSize: 34,
            color: "#444444",
            //backgroundColor: "whitesmoke",
            display: "flex",

            borderRadius: 5,
            margin: 10,
            marginBottom: 0,
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <div
            className={`row ${classes.ResponsiveRow}`}
            style={{ marginTop: 20 }}
          >
            {flavors}
          </div>
        </div>
      </div>
    </>
  );

  const spanishDetailView = (
    <>
      <div className={classes.LandingImage}>
        {/* <img src={logo} className={"img-fluid"} /> */}
        <Image src={spanishLanding} fluid />
      </div>
      <div className={classes.BottomAppStoreMessage}>
        {/* ...coming soon to iOS */}
        <a
          href={`https://apps.apple.com/us/app/study-squares-spanish-vocab/id1548533157#?platform=iphone`}
          style={{
            display: "inline-block",
            overflow: "hidden",
            borderRadius: 13,
            width: 53,
            height: 53,
            backgroundColor: "white",
            marginRight: 10,
          }}
        >
          <img
            src={languageHuntLogo}
            alt="LanguageHunt App Store Link"
            style={{ borderRadius: 13, width: 47, height: 47 }}
          />
        </a>
        <a
          href={`https://apps.apple.com/us/app/study-squares-spanish-vocab/id1548533157#?platform=iphone`}
          style={{
            display: "inline-block",
            overflow: "hidden",
            borderRadius: 13,
            width: 150,
            height: 53,
          }}
        >
          <img
            src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-US?size=250x83&amp;releaseDate=1286323200&h=18f79cd4c60f3a0a079c6b8b1ee6f670"
            alt="Download on the App Store"
            style={{ borderRadius: 13, width: 150, height: 53 }}
          />
        </a>
      </div>
      <div className="container">
        <div className={`row ${classes.ResponsiveRow}`}>
          <div
            style={{
              margin: 20,
              textAlign: "center",
            }}
          >
            <div className={classes.DetailsSectionTitle}>Marketing</div>
            <iframe
              width="311"
              height="175"
              marginTop="100"
              src="https://www.youtube.com/embed/C3Y44OLICrQ?si=lbA1VY5wUKd7J6fH"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
          </div>
          <div
            style={{
              margin: 20,
            }}
          >
            <div className={classes.DetailsSectionTitle}>Walkthrough</div>
            <iframe
              width="311"
              height="175"
              src="https://www.youtube.com/embed/HFPulwJ-Ey0?si=6ILWMSryChqg0qgy"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
          </div>
        </div>
      </div>
    </>
  );

  return (
    <div>
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          backgroundColor: "whitesmoke",
          width: "100vw",
          //height: "100%",
          display: "flex",
          zIndex: -1,
        }}
      ></div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          // border: "solid",
          // borderWidth: 1,
          //borderColor: "red",
          //height: "100vh",
          flex: 1,
        }}
      >
        <div
          style={{
            marginTop: 10,
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-around",
            // borderWidth: 1,
            // borderColor: "red",
            // border: "solid",
            display: "flex",
          }}
        >
          <div className={classes.Title}>Study Squares</div>
          <div className={classes.SubTitle}>
            Turn your Home Screen into a{" "}
            {selectedTopic ? titleInsertionView : null} Flashcard!
          </div>
          {spanishDetailView}
        </div>
      </div>
      {/* {selectedTopic ? null : topicSelectionView} */}
    </div>
  );
};

export default StudySquaresPage;
