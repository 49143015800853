import React from "react";

import Image from "react-bootstrap/Image";

import alexaStatsLanding from "../../assets/images/alexa_stats_landing.png";
import alexaStatsLogo from "../../assets/images/alexa_stats_logo_no_bg.png";
import mtntopLogo from "../../assets/images/mtn_top_blue_on_clear_no_words_thick.png";
import teachersNoteLogo from "../../assets/images/teachers_note_launch_transparent.png";
import holyMinuteLogo from "../../assets/images/holyminute_logo_no_bg.png";

//import PrivacyPolicy from "../../components/PrivacyPolicy/PrivacyPolicy"

import classes from "./MtnTopPlatformPage.module.scss";

const AlexaStatsPage = (props) => {
  const mtntopProduct = (
    <a
      key={1}
      style={{
        //width: 200,
        height: 200,
        //backgroundColor: "whitesmoke",
        // margin: 10,
        borderRadius: 6,
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
        marginBottom: 65,
        //margin: 8,
      }}
      className={`${classes.CaseStudyCard} col-sm-12 col-lg-4 col-md-6`}
      href={"https://mtntopshout.com"}
    >
      {" "}
      <div className={classes.LogoImage}>
        {/* <img src={logo} className={"img-fluid"} /> */}
        <Image src={mtntopLogo} fluid />
      </div>
    </a>
  );
  const teachersNoteProduct = (
    <a
      key={2}
      style={{
        //width: 200,
        height: 200,
        //backgroundColor: "whitesmoke",
        //margin: 10,
        borderRadius: 6,
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
        marginBottom: 65,
        //margin: 8,
      }}
      className={`${classes.CaseStudyCard} col-sm-12 col-lg-4 col-md-6`}
      href={"https://myteachersnote.com"}
    >
      {" "}
      <div className={classes.LogoImage}>
        {/* <img src={logo} className={"img-fluid"} /> */}
        <Image src={teachersNoteLogo} fluid />
      </div>
    </a>
  );
  const holyMinuteProduct = (
    <a
      key={3}
      style={{
        //width: 200,
        height: 200,
        //backgroundColor: "whitesmoke",
        //margin: 10,
        borderRadius: 6,
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
        marginBottom: 65,
        //margin: 8,
      }}
      href={"https://holymin.com"}
      className={`${classes.CaseStudyCard} col-sm-12 col-lg-4 col-md-6`}
      // onClick={() => {
      //   // history.push(`/case-study/${CASE_STUDIES[0].id}`);
      //   console.log("clicked on mtntop");
      // }}
    >
      {" "}
      <div className={classes.LogoImage}>
        {/* <img src={logo} className={"img-fluid"} /> */}
        <Image src={holyMinuteLogo} fluid />
      </div>
    </a>
  );

  const productsToShow = [
    mtntopProduct,
    teachersNoteProduct,
    holyMinuteProduct,
  ];

  return (
    <div>
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          backgroundColor: "whitesmoke",
          width: "100vw",
          //height: "100vh",
          zIndex: -1,
        }}
      ></div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          // border: "solid",
          // borderWidth: 1,
          //borderColor: "red",
          //height: "100vh",
          flex: 1,
        }}
      >
        <div
          style={{
            marginTop: 10,
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-around",
            // borderWidth: 1,
            // borderColor: "red",
            // border: "solid",
            display: "flex",
          }}
        >
          <div className={classes.Title}>MtnTop</div>
          <div className={classes.SubTitle}>A Social Voice Platform</div>
          <div className={classes.FullScreenMessage} style={{ ...props.style }}>
            <div className={classes.Subtitle} key={21}>
              MtnTop is a set of reusable frontend and backend components
              allowing for the construction of social voice platforms. These
              components facilitate the creation of systems that allow users to
              post recorded and written content from a mobile app; other users
              may then follow and listen to that content via mobile, the web,
              Alexa, or Google Assistant. Account management functionality is
              also available via the web.
              <br></br>
              <br></br>To date three apps have been built from this set of
              components:
              <br></br>
              <br></br>MtnTop
              <br></br>Taking its name from the base it is built upon, MtnTop is
              a marketing platform designed to help small businesses grow their
              audience on voice. Businesses get easy access to custom Alexa
              skills and Google actions and can dynamically update the content
              they surface.
              <br></br>
              <br></br>Teacher’s Note
              <br></br>This platform allows teachers to post recorded classroom
              messages from a mobile app. Their students can then access these
              messages via the mobile app, Alexa, or Google Assistant. Teacher’s
              Note comes with class management features including optional
              password protection for classes.
              <br></br>
              <br></br>Holy Minute
              <br></br>This platform allows priests and religious to post
              recorded prayers and reflections via a mobile app. Interested
              listeners can then access these posts via mobile, Alexa, Google
              Assistant, or the web.
              <br></br>
              <br></br>
              Find out more about each of the above by clicking on their logos
              below or watching the walkthrough video.
            </div>
          </div>
          <div className={classes.DetailsSectionTitle}>
            Built using the MtnTop platform:
          </div>
          <div
            className={`row ${classes.ResponsiveRow}`}
            style={{ marginTop: 20 }}
          >
            {productsToShow}
          </div>
          <div className="container">
            <div className={`row ${classes.ResponsiveRow}`}>
              <div
                style={{
                  margin: 20,
                  marginTop: 0,
                }}
              >
                <div className={classes.DetailsSectionTitle}>Walkthrough</div>
                <iframe
                  width="311"
                  height="175"
                  src="https://www.youtube.com/embed/1T72xIiFSk4?si=yQm-nUwzpRra5jUR"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AlexaStatsPage;
