import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";

import classes from "./TermsAndConditions.module.scss";

import dev_config from "../../config_dev";
import prod_config from "../../config_prod";
const config = dev_config.useProd ? prod_config : dev_config;

const TermsAndConditions = (props) => {
  return (
    <div className={classes.FullScreenMessage} style={{ ...props.style }}>
      <div className={classes.Title} style={{ ...props.titleStyle }} key={20}>
        Terms and Conditions
      </div>
      <div className={classes.SubHeading}>
        Agreement between User and {` ${config.loginTitle}`}
      </div>
      <div className={classes.Subtitle}>
        <br></br>
        Welcome to {` ${config.loginTitle}`}. The {` ${config.loginTitle}`}{" "}
        platform (the "Platform") is comprised of various web pages, mobile
        applications, and voice integrations operated by Harbour View
        Technologies LLC ("Harbour View Technologies").{" "}
        {` ${config.loginTitle}`} is offered to you conditioned on your
        acceptance without modification of the terms, conditions, and notices
        contained herein (the "Terms"). Your use of {` ${config.loginTitle} `}{" "}
        constitutes your agreement to all such Terms. Please read these terms
        carefully, and keep a copy of them for your reference.<br></br>
        <br></br>
        {`${config.loginTitle} `} is {` ${config.platformDescription} `} .
        <br></br>
        <br></br>
        {` ${config.loginTitle} `} comes with access to one hunt in each
        supported language. LanguageHunt Pro is available for purchase from
        within the app. LangugageHunt Pro gives the user lifetime access to core
        LanguageHunt hunt categories: Kitchen Tools, Around the House, Food,
        Electronics, Sports, Animals, Transportation, and Instruments. We
        reserve the right to add new hunts not included in LanguageHunt Pro.
      </div>
      <br></br>
      <div className={classes.SubHeading}>Privacy</div>
      <div className={classes.Subtitle}>
        <br></br>
        Your use of {` ${config.loginTitle} `} is subject to{" "}
        {` ${config.loginTitle}`}'s Privacy Policy. Please review our Privacy
        Policy, which also governs the Platform and informs users of our data
        collection practices.
      </div>
      <br></br>
      <div className={classes.SubHeading}>Electronic Communications</div>
      <div className={classes.Subtitle}>
        <br></br>
        Visiting {` ${config.loginTitle} `} or sending emails to Harbour View
        Technologies constitutes electronic communications. You consent to
        receive electronic communications and you agree that all agreements,
        notices, disclosures and other communications that we provide to you
        electronically, via email and on the Platform, satisfy any legal
        requirement that such communications be in writing.
      </div>
      <br></br>
      <div className={classes.SubHeading}>Your Account</div>
      <div className={classes.Subtitle}>
        <br></br>
        If you use this platform, you are responsible for maintaining the
        confidentiality of your account and password and for restricting access
        to your computer, and you agree to accept responsibility for all
        activities that occur under your account or password. You may not assign
        or otherwise transfer your account to any other person or entity. You
        acknowledge that Harbour View Technologies is not responsible for third
        party access to your account that results from theft or misappropriation
        of your account. Harbour View Technologies and its associates reserve
        the right to refuse or cancel service, terminate accounts, or remove or
        edit content in our sole discretion.
      </div>
      <br></br>
      <div className={classes.SubHeading}>Children Under Thirteen</div>
      <div className={classes.Subtitle}>
        <br></br>
        Harbour View Technologies does not knowingly collect, either online or
        offline, personal information from persons under the age of thirteen. If
        you are under 18, you may use {` ${config.loginTitle} `} only with
        permission of a parent or guardian.
      </div>
      <br></br>
      <div className={classes.SubHeading}>Cancellation/Refund Policy</div>
      <div className={classes.Subtitle}>
        <br></br>
        You may cancel automatic contributions at any time. Contribution refunds
        will not be given for past billed dates - only future contributions will
        be canceled upon cancellation of automatic contributions.
      </div>
      <br></br>
      <div className={classes.SubHeading}>
        Links to Third Party Sites/Third Party Services
      </div>
      <div className={classes.Subtitle}>
        <br></br>
        {`${config.loginTitle} `} may contain links to other websites, apps,
        software, and services ("Linked Sites"). The Linked Sites are not under
        the control of Harbour View Technologies and Harbour View Technologies
        is not responsible for the contents of any Linked Site, including
        without limitation any link contained in a Linked Site, or any changes
        or updates to a Linked Site. Harbour View Technologies is providing
        these links to you only as a convenience, and the inclusion of any link
        does not imply endorsement by Harbour View Technologies of the site or
        any association with its operators.
        <br></br>
        <br></br>Certain services made available via {` ${config.loginTitle} `}{" "}
        are delivered by third party sites and organizations. By using any
        product, service or functionality originating from the{" "}
        {` ${config.loginTitle} `} platform, you hereby acknowledge and consent
        that Harbour View Technologies may share such information and data with
        any third party with whom Harbour View Technologies has a contractual
        relationship to provide the requested product, service or functionality
        on behalf of {` ${config.loginTitle} `}
        users and customers.
        <br></br>
        <br></br>
        Some portions of this platform are protected by reCAPTCHA and the Google{" "}
        <a href="https://policies.google.com/privacy" className={classes.Link}>
          Privacy Policy
        </a>{" "}
        and{" "}
        <a href="https://policies.google.com/terms" className={classes.Link}>
          Terms of Service
        </a>{" "}
        apply.
      </div>
      <br></br>
      <div className={classes.SubHeading}>
        No Unlawful or Prohibited Use/Intellectual Property{" "}
      </div>
      <div className={classes.Subtitle}>
        <br></br>
        You are granted a non-exclusive, non-transferable, revocable license to
        access and use {` ${config.loginTitle} `} strictly in accordance with
        these terms of use. As a condition of your use of the Platform, you
        warrant to Harbour View Technologies that you will not use the Platform
        for any purpose that is unlawful or prohibited by these Terms. You may
        not use the Platform in any manner which could damage, disable,
        overburden, or impair the Platform or interfere with any other party's
        use and enjoyment of the Platform. You may not obtain or attempt to
        obtain any materials or information through any means not intentionally
        made available or provided for through the Platform.
        <br></br>
        <br></br>
        All content included as part of the Service, such as text, graphics,
        logos, images, as well as the compilation thereof, and any software used
        on the Platform, is the property of Harbour View Technologies or its
        suppliers and protected by copyright and other laws that protect
        intellectual property and proprietary rights. You agree to observe and
        abide by all copyright and other proprietary notices, legends or other
        restrictions contained in any such content and will not make any changes
        thereto.
        <br></br>
        <br></br>
        You will not modify, publish, transmit, reverse engineer, participate in
        the transfer or sale, create derivative works, or in any way exploit any
        of the content, in whole or in part, found on the Platform. Harbour View
        Technologies content is not for resale. Your use of the Platform does
        not entitle you to make any unauthorized use of any protected content,
        and in particular you will not delete or alter any proprietary rights or
        attribution notices in any content. You will use protected content
        solely for your personal use, and will make no other use of the content
        without the express written permission of Harbour View Technologies and
        the copyright owner. You agree that you do not acquire any ownership
        rights in any protected content. We do not grant you any licenses,
        express or implied, to the intellectual property of Harbour View
        Technologies or our licensors except as expressly authorized by these
        Terms.
      </div>
      <br></br>
      <div className={classes.SubHeading}>Use of Communication Services</div>
      <div className={classes.Subtitle}>
        <br></br>
        The Platform may contain bulletin board services, chat areas, news
        groups, forums, communities, personal web pages, calendars, and/or other
        message or communication facilities designed to enable you to
        communicate with the public at large or with a group (collectively,
        "Communication Services"). You agree to use the Communication Services
        only to post, send and receive messages and material that are proper and
        related to the particular Communication Service.
        <br></br>
        <br></br>
        By way of example, and not as a limitation, you agree that when using a
        Communication Service, you will not: defame, abuse, harass, stalk,
        threaten or otherwise violate the legal rights (such as rights of
        privacy and publicity) of others; publish, post, upload, distribute or
        disseminate any inappropriate, profane, defamatory, infringing, obscene,
        indecent or unlawful topic, name, material or information; upload files
        that contain software or other material protected by intellectual
        property laws (or by rights of privacy of publicity) unless you own or
        control the rights thereto or have received all necessary consents;
        upload files that contain viruses, corrupted files, or any other similar
        software or programs that may damage the operation of another's
        computer; advertise or offer to sell or buy any goods or services for
        any business purpose, unless such Communication Service specifically
        allows such messages; conduct or forward surveys, contests, pyramid
        schemes or chain letters; download any file posted by another user of a
        Communication Service that you know, or reasonably should know, cannot
        be legally distributed in such manner; falsify or delete any author
        attributions, legal or other proper notices or proprietary designations
        or labels of the origin or source of software or other material
        contained in a file that is uploaded; restrict or inhibit any other user
        from using and enjoying the Communication Services; violate any code of
        conduct or other guidelines which may be applicable for any particular
        Communication Service; harvest or otherwise collect information about
        others, including e-mail addresses, without their consent; violate any
        applicable laws or regulations.
        <br></br>
        <br></br>
        Harbour View Technologies has no obligation to monitor the Communication
        Services. However, Harbour View Technologies reserves the right to
        review materials posted to a Communication Service and to remove any
        materials in its sole discretion. Harbour View Technologies reserves the
        right to terminate your access to any or all of the Communication
        Services at any time without notice for any reason whatsoever.
        <br></br>
        <br></br>
        Harbour View Technologies reserves the right at all times to disclose
        any information as necessary to satisfy any applicable law, regulation,
        legal process or governmental request, or to edit, refuse to post or to
        remove any information or materials, in whole or in part, in Harbour
        View Technologies's sole discretion.
        <br></br>
        <br></br>
        Always use caution when giving out any personally identifying
        information about yourself or your children in any Communication
        Service. Harbour View Technologies does not control or endorse the
        content, messages or information found in any Communication Service and,
        therefore, Harbour View Technologies specifically disclaims any
        liability with regard to the Communication Services and any actions
        resulting from your participation in any Communication Service. Managers
        and hosts are not authorized Harbour View Technologies spokespersons,
        and their views do not necessarily reflect those of Harbour View
        Technologies.
        <br></br>
        <br></br>
        Materials uploaded to a Communication Service may be subject to posted
        limitations on usage, reproduction and/or dissemination. You are
        responsible for adhering to such limitations if you upload the
        materials.
      </div>
      <br></br>
      <div className={classes.SubHeading}>
        Materials Provided to {` ${config.loginTitle} `} or Posted on Any
        Harbour View Technologies Software
      </div>
      <div className={classes.Subtitle}>
        <br></br>
        Harbour View Technologies does not claim ownership of the materials you
        provide to {` ${config.loginTitle} `} (including feedback and
        suggestions) or post, upload, input or submit to any Harbour View
        Technologies software or our associated services (collectively
        "Submissions"). However, by posting, uploading, inputting, providing or
        submitting your Submission you are granting Harbour View Technologies,
        our affiliated companies and necessary sublicensees permission to use
        your Submission in connection with the operation of their Internet
        businesses including, without limitation, the rights to: copy,
        distribute, transmit, publicly display, publicly perform, reproduce,
        edit, translate and reformat your Submission; and to publish your name
        in connection with your Submission.
        <br></br>
        <br></br>
        No compensation will be paid with respect to the use of your Submission,
        as provided herein. Harbour View Technologies is under no obligation to
        post or use any Submission you may provide and may remove any Submission
        at any time in Harbour View Technologies's sole discretion.
        <br></br>
        <br></br>
        By posting, uploading, inputting, providing or submitting your
        Submission you warrant and represent that you own or otherwise control
        all of the rights to your Submission as described in this section
        including, without limitation, all the rights necessary for you to
        provide, post, upload, input or submit the Submissions.
      </div>
      <br></br>
      <div className={classes.SubHeading}>International Users</div>
      <div className={classes.Subtitle}>
        <br></br>
        The Service is controlled, operated and administered by Harbour View
        Technologies from our offices within the USA. If you access the Service
        from a location outside the USA, you are responsible for compliance with
        all local laws. You agree that you will not use the Harbour View
        Technologies Content accessed through {` ${config.loginTitle} `} in any
        country or in any manner prohibited by any applicable laws, restrictions
        or regulations.
      </div>
      <br></br>
      <div className={classes.SubHeading}>Indemnification</div>
      <div className={classes.Subtitle}>
        <br></br>
        You agree to indemnify, defend and hold harmless Harbour View
        Technologies, its officers, directors, employees, agents and third
        parties, for any losses, costs, liabilities and expenses (including
        reasonable attorney's fees) relating to or arising out of your use of or
        inability to use the Platform or services, any user postings made by
        you, your violation of any terms of this Agreement or your violation of
        any rights of a third party, or your violation of any applicable laws,
        rules or regulations. Harbour View Technologies reserves the right, at
        its own cost, to assume the exclusive defense and control of any matter
        otherwise subject to indemnification by you, in which event you will
        fully cooperate with Harbour View Technologies in asserting any
        available defenses.
      </div>
      <br></br>
      <div className={classes.SubHeading}>Class Action Waiver</div>
      <div className={classes.Subtitle}>
        <br></br>
        Any arbitration under these Terms and Conditions will take place on an
        individual basis; class arbitrations and class/representative/collective
        actions are not permitted. THE PARTIES AGREE THAT A PARTY MAY BRING
        CLAIMS AGAINST THE OTHER ONLY IN EACH'S INDIVIDUAL CAPACITY, AND NOT AS
        A PLAINTIFF OR CLASS MEMBER IN ANY PUTATIVE CLASS, COLLECTIVE AND/ OR
        REPRESENTATIVE PROCEEDING, SUCH AS IN THE FORM OF A PRIVATE ATTORNEY
        GENERAL ACTION AGAINST THE OTHER. Further, unless both you and Harbour
        View Technologies agree otherwise, the arbitrator may not consolidate
        more than one person's claims, and may not otherwise preside over any
        form of a representative or class proceeding.
      </div>
      <br></br>
      <div className={classes.SubHeading}>Liability Disclaimer</div>
      <div className={classes.Subtitle}>
        <br></br>
        THE INFORMATION, SOFTWARE, PRODUCTS, AND SERVICES INCLUDED IN OR
        AVAILABLE THROUGH THE PLATFORM MAY INCLUDE INACCURACIES OR TYPOGRAPHICAL
        ERRORS. CHANGES ARE PERIODICALLY ADDED TO THE INFORMATION HEREIN.
        HARBOUR VIEW TECHNOLOGIES LLC AND/OR ITS SUPPLIERS MAY MAKE IMPROVEMENTS
        AND/OR CHANGES IN THE PLATFORM AT ANY TIME.
        <br></br>
        <br></br>
        HARBOUR VIEW TECHNOLOGIES LLC AND/OR ITS SUPPLIERS MAKE NO
        REPRESENTATIONS ABOUT THE SUITABILITY, RELIABILITY, AVAILABILITY,
        TIMELINESS, AND ACCURACY OF THE INFORMATION, SOFTWARE, PRODUCTS,
        SERVICES AND RELATED GRAPHICS CONTAINED ON THE PLATFORM FOR ANY PURPOSE.
        TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, ALL SUCH INFORMATION,
        SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS ARE PROVIDED "AS IS"
        WITHOUT WARRANTY OR CONDITION OF ANY KIND. HARBOUR VIEW TECHNOLOGIES LLC
        AND/OR ITS SUPPLIERS HEREBY DISCLAIM ALL WARRANTIES AND CONDITIONS WITH
        REGARD TO THIS INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED
        GRAPHICS, INCLUDING ALL IMPLIED WARRANTIES OR CONDITIONS OF
        MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND
        NON-INFRINGEMENT.
        <br></br>
        <br></br>
        TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL
        HARBOUR VIEW TECHNOLOGIES LLC AND/OR ITS SUPPLIERS BE LIABLE FOR ANY
        DIRECT, INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL, CONSEQUENTIAL DAMAGES
        OR ANY DAMAGES WHATSOEVER INCLUDING, WITHOUT LIMITATION, DAMAGES FOR
        LOSS OF USE, DATA OR PROFITS, ARISING OUT OF OR IN ANY WAY CONNECTED
        WITH THE USE OR PERFORMANCE OF THE PLATFORM, WITH THE DELAY OR INABILITY
        TO USE THE PLATFORM OR RELATED SERVICES, THE PROVISION OF OR FAILURE TO
        PROVIDE SERVICES, OR FOR ANY INFORMATION, SOFTWARE, PRODUCTS, SERVICES
        AND RELATED GRAPHICS OBTAINED THROUGH THE PLATFORM, OR OTHERWISE ARISING
        OUT OF THE USE OF THE PLATFORM, WHETHER BASED ON CONTRACT, TORT,
        NEGLIGENCE, STRICT LIABILITY OR OTHERWISE, EVEN IF HARBOUR VIEW
        TECHNOLOGIES LLC OR ANY OF ITS SUPPLIERS HAS BEEN ADVISED OF THE
        POSSIBILITY OF DAMAGES. BECAUSE SOME STATES/JURISDICTIONS DO NOT ALLOW
        THE EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL
        DAMAGES, THE ABOVE LIMITATION MAY NOT APPLY TO YOU. IF YOU ARE
        DISSATISFIED WITH ANY PORTION OF THE PLATFORM, OR WITH ANY OF THESE
        TERMS OF USE, YOUR SOLE AND EXCLUSIVE REMEDY IS TO DISCONTINUE USING THE
        PLATFORM.
      </div>
      <br></br>
      <div className={classes.SubHeading}>Termination/Access Restriction </div>
      <div className={classes.Subtitle}>
        <br></br>
        Harbour View Technologies reserves the right, in its sole discretion, to
        terminate your access to the Platform and the related services or any
        portion thereof at any time, without notice. To the maximum extent
        permitted by law, this agreement is governed by the laws of the State of
        Michigan and you hereby consent to the exclusive jurisdiction and venue
        of courts in Michigan in all disputes arising out of or relating to the
        use of the Platform. Use of the Platform is unauthorized in any
        jurisdiction that does not give effect to all provisions of these Terms,
        including, without limitation, this section.
        <br></br>
        <br></br>
        You agree that no joint venture, partnership, employment, or agency
        relationship exists between you and Harbour View Technologies as a
        result of this agreement or use of the Platform. Harbour View
        Technologies's performance of this agreement is subject to existing laws
        and legal process, and nothing contained in this agreement is in
        derogation of Harbour View Technologies's right to comply with
        governmental, court and law enforcement requests or requirements
        relating to your use of the Platform or information provided to or
        gathered by Harbour View Technologies with respect to such use. If any
        part of this agreement is determined to be invalid or unenforceable
        pursuant to applicable law including, but not limited to, the warranty
        disclaimers and liability limitations set forth above, then the invalid
        or unenforceable provision will be deemed superseded by a valid,
        enforceable provision that most closely matches the intent of the
        original provision and the remainder of the agreement shall continue in
        effect.
        <br></br>
        <br></br>
        Unless otherwise specified herein, this agreement constitutes the entire
        agreement between the user and Harbour View Technologies with respect to
        the Platform and it supersedes all prior or contemporaneous
        communications and proposals, whether electronic, oral or written,
        between the user and Harbour View Technologies with respect to the
        Platform. A printed version of this agreement and of any notice given in
        electronic form shall be admissible in judicial or administrative
        proceedings based upon or relating to this agreement to the same extent
        and subject to the same conditions as other business documents and
        records originally generated and maintained in printed form. It is the
        express wish to the parties that this agreement and all related
        documents be written in English.
      </div>

      <br></br>
      <div className={classes.SubHeading}>Changes to Terms</div>
      <div className={classes.Subtitle}>
        <br></br>
        Harbour View Technologies reserves the right, in its sole discretion, to
        change the Terms under which {` ${config.loginTitle} `} is offered. The
        most current version of the Terms will supersede all previous versions.
        Harbour View Technologies encourages you to periodically review the
        Terms to stay informed of our updates.
      </div>
      <br></br>
      <div className={classes.SubHeading}>Contact Us</div>
      <div className={classes.Subtitle}>
        <br></br>
        Harbour View Technologies welcomes your questions or comments regarding
        the Terms:
        <br></br>
        <br></br>
        Harbour View Technologies LLC
        <br></br>
        Email:
        <br></br>
        inquiries@harbourviewtechnologies.com
        <br></br>
        <br></br>
        <br></br>
        Effective as of October 29, 2020
        <br></br>
        <br></br>
      </div>
      <br></br>
    </div>
  );
};

export default TermsAndConditions;
