import React from "react";

import Image from "react-bootstrap/Image";

import languageHuntLanding from "../../assets/images/language_hunt_landing.png";
import languageHuntLogo from "../../assets/images/language_hunt_logo.png";

//import PrivacyPolicy from "../../components/PrivacyPolicy/PrivacyPolicy"

import classes from "./LanguageHuntPage.module.scss";

const LanguageHuntPage = (props) => {
  return (
    <div>
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          backgroundColor: "whitesmoke",
          width: "100vw",
          //height: "100vh",
          zIndex: -1,
        }}
      ></div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          // border: "solid",
          // borderWidth: 1,
          //borderColor: "red",
          //height: "100vh",
          flex: 1,
        }}
      >
        <div
          style={{
            marginTop: 10,
            marginBottom: 40,
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-around",
            // borderWidth: 1,
            // borderColor: "red",
            // border: "solid",
            display: "flex",
          }}
        >
          <div className={classes.Title}>LanguageHunt</div>
          <div className={classes.SubTitle}>
            Learn new languages using the real world around you!
          </div>
          <div className={classes.LandingImage}>
            {/* <img src={logo} className={"img-fluid"} /> */}
            <Image src={languageHuntLanding} fluid />
          </div>
          <div className="container">
            <div className={`row ${classes.ResponsiveRow}`}>
              <div
                style={{
                  margin: 20,
                  textAlign: "center",
                }}
              >
                <div className={classes.DetailsSectionTitle}>Marketing</div>
                <iframe
                  width="311"
                  height="175"
                  marginTop="100"
                  src="https://www.youtube.com/embed/2mimpc96DWk?si=Pevdhb5ti2-0vgPT"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              </div>
              <div
                style={{
                  margin: 20,
                }}
              >
                <div className={classes.DetailsSectionTitle}>Walkthrough</div>
                <iframe
                  width="311"
                  height="175"
                  src="https://www.youtube.com/embed/T9trjMjHurI?si=yggnK64ITVYlBkzY"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LanguageHuntPage;
