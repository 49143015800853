import React from "react";

import Image from "react-bootstrap/Image";

import roundballLanding from "../../assets/images/swiftlinks_landing.png";
import roundballLogo from "../../assets/images/roundball_logo.png";

//import PrivacyPolicy from "../../components/PrivacyPolicy/PrivacyPolicy"

import classes from "./SwiftLinksPage.module.scss";

const LanguageHuntPage = (props) => {
  return (
    <div>
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          backgroundColor: "whitesmoke",
          width: "100vw",
          //height: "100vh",
          zIndex: -1,
        }}
      ></div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          // border: "solid",
          // borderWidth: 1,
          //borderColor: "red",
          //height: "100vh",
          flex: 1,
        }}
      >
        <div
          style={{
            marginTop: 10,
            marginBottom: 40,
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-around",
            // borderWidth: 1,
            // borderColor: "red",
            // border: "solid",
            display: "flex",
          }}
        >
          <div className={classes.Title}>SwiftLinks</div>
          <div className={classes.SubTitle}>Create GitHub links...Swiftly</div>
          <div className={classes.Subsubtitle}>For macOS</div>
          <div className={classes.LandingWrapper}>
            <div className={classes.LandingImage}>
              {/* <img src={logo} className={"img-fluid"} /> */}
              <Image src={roundballLanding} fluid />
            </div>
          </div>
          <div className={classes.BottomAppStoreMessage}>
            {/* ...coming soon to iOS */}

            <a
              href={`https://apps.apple.com/us/app/swiftlinks/id6469731311`}
              style={{
                display: "inline-block",
                overflow: "hidden",
                borderRadius: 13,
                width: 150,
                height: 53,
              }}
            >
              <img
                src="https://tools.applemediaservices.com/api/badges/download-on-the-mac-app-store/black/en-us?size=250x83&amp"
                alt="Download on the App Store"
                style={{ borderRadius: 13, width: 150, height: 53 }}
              />
            </a>
          </div>
          <div className="container">
            <div className={`row ${classes.ResponsiveRow}`}>
              <div
                style={{
                  margin: 20,
                }}
              >
                <div className={classes.DetailsSectionTitle}>Walkthrough</div>
                <iframe
                  width="311"
                  height="175"
                  src="https://www.youtube.com/embed/VoVZyTNFloI?si=tdyo_p8hj3K2ySBX"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LanguageHuntPage;
