import React from "react";

import Image from "react-bootstrap/Image";

import languageHuntLanding from "../../assets/images/little_timer_landing.png";
import littleTimerLogo from "../../assets/images/littletimer_darkbg.png";

//import PrivacyPolicy from "../../components/PrivacyPolicy/PrivacyPolicy"

import classes from "./LittleTimerPage.module.scss";

const LittleTimerPage = (props) => {
  return (
    <div>
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          backgroundColor: "whitesmoke",
          width: "100vw",
          zIndex: -1,
        }}
      ></div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          // border: "solid",
          // borderWidth: 1,
          //borderColor: "red",
          //height: "100vh",
          flex: 1,
        }}
      >
        <div
          style={{
            marginTop: 10,
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-around",
            // borderWidth: 1,
            // borderColor: "red",
            // border: "solid",
            display: "flex",
          }}
        >
          <div className={classes.Title}>LittleTimer</div>
          <div className={classes.SubTitle}>
            The timer that doesn't cover your screen!
          </div>
          <div className={classes.LandingImage}>
            {/* <img src={logo} className={"img-fluid"} /> */}
            <Image src={languageHuntLanding} fluid />
          </div>
          <div className="container">
            <div className={`row ${classes.ResponsiveRow}`}>
              <div
                style={{
                  margin: 20,
                }}
              >
                <div className={classes.DetailsSectionTitle}>Walkthrough</div>
                <iframe
                  width="311"
                  height="175"
                  src="https://youtube.com/embed/sv8LysHMfVc?si=BLeVEtitb-khaacz"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              </div>
            </div>
          </div>
          <div className={classes.BottomAppStoreMessage}>
            {/* ...coming soon to iOS */}
            {/* <a
              href={`https://apps.apple.com/us/app/languagehunt-word-teacher/id1537957811?itsct=apps_box&amp;itscg=30200`}
              style={{
                display: "inline-block",
                overflow: "hidden",
                borderRadius: 13,
                width: 53,
                height: 53,
                //backgroundColor: "white",
                marginRight: 10,
              }}
            >
              <img
                src={littleTimerLogo}
                alt="LittleTimer App Store Link"
                style={{ borderRadius: 13, width: 47, height: 47 }}
              />
            </a>
            <a
              href={`https://apps.apple.com/us/app/languagehunt-word-teacher/id1537957811?itsct=apps_box&amp;itscg=30200`}
              style={{
                display: "inline-block",
                overflow: "hidden",
                borderRadius: 13,
                width: 150,
                height: 53,
              }}
            >
              <img
                src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-US?size=250x83&amp;releaseDate=1286323200&h=18f79cd4c60f3a0a079c6b8b1ee6f670"
                alt="Download on the App Store"
                style={{ borderRadius: 13, width: 150, height: 53 }}
              />
            </a> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LittleTimerPage;
