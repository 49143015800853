import React from "react";
import { Route, Switch, withRouter, Redirect } from "react-router-dom";

import Banner from "./components/Banner/Banner";
import Layout from "./hoc/Layout/Layout";
import LandingPage from "./containers/LandingPage/LandingPage";
import PrivacyPolicyPage from "./containers/PrivacyPolicyPage/PrivacyPolicyPage";
import TermsAndConditionsPage from "./containers/TermsAndConditionsPage/TermsAndConditionsPage";
import LanguageHuntPage from "./containers/LanguageHuntPage/LanguageHuntPage";
import LittleTimerPage from "./containers/LittleTimerPage/LittleTimerPage";
import StudySquaresPage from "./containers/StudySquaresPage/StudySquaresPage";
import RoundBallPage from "./containers/RoundBallPage/RoundBallPage";
import SwiftLinksPage from "./containers/SwiftLinksPage/SwiftLinksPage";
import AlexaStatsPage from "./containers/AlexaStatsPage/AlexaStatsPage";
import MtnTopPlatformPage from "./containers/MtnTopPlatformPage/MtnTopPlatformPage";
import DesignPage from "./containers/DesignPage/DesignPage";

import "./App.scss";
import "animate.css/animate.min.css";

function App() {
  return (
    // <div className="site-background-container">
    //   <div className="site-background">
    //     <Layout>
    //       <Switch>
    //         <Route
    //           path="/"
    //           exact
    //           render={(props) => <LandingPage></LandingPage>}
    //         />
    //         <Redirect to="/" />
    //       </Switch>
    //     </Layout>
    //   </div>
    // </div>

    <Layout>
      <div id="home" className="site-background-container">
        {/* <div className="site-background" style={{ zIndex: 20 }}>
          <Banner id="home"></Banner>
        </div> */}
        <Switch>
          <Route
            path="/design/clothing"
            exact
            render={(props) => <DesignPage></DesignPage>}
          />
          <Route
            path="/languagehunt/about"
            exact
            render={(props) => <LanguageHuntPage></LanguageHuntPage>}
          />
          <Route
            path="/languagehunt/privacy-policy"
            exact
            render={(props) => (
              <PrivacyPolicyPage
                appTitle={"LanguageHunt"}
                titleColor={"#81b29a"}
                effectiveDate={"29 October 2020"}
              ></PrivacyPolicyPage>
            )}
          />
          <Route
            path="/littletimer/about"
            exact
            render={(props) => <LittleTimerPage></LittleTimerPage>}
          />
          <Route
            path="/littletimer/privacy-policy"
            exact
            render={(props) => (
              <PrivacyPolicyPage
                appTitle={"LittleTimer"}
                titleColor={"#444444"}
                effectiveDate={"30 November 2020"}
              ></PrivacyPolicyPage>
            )}
          />
          <Route
            path="/mtntop-platform/about"
            exact
            render={(props) => <MtnTopPlatformPage></MtnTopPlatformPage>}
          />
          <Route
            path="/languagehunt/terms-and-conditions"
            exact
            render={(props) => (
              <TermsAndConditionsPage></TermsAndConditionsPage>
            )}
          />
          <Route
            path="/studysquares/about"
            exact
            render={(props) => <StudySquaresPage></StudySquaresPage>}
          />
          <Route
            path="/studysquares/privacy-policy"
            exact
            render={(props) => (
              <PrivacyPolicyPage
                appTitle={"Study Squares"}
                titleColor={"#1D3557"}
                effectiveDate={"1 February 2021"}
              ></PrivacyPolicyPage>
            )}
          />
          <Route
            path="/alexastats/about"
            exact
            render={(props) => <AlexaStatsPage></AlexaStatsPage>}
          />
          <Route
            path="/alexastats/privacy-policy"
            exact
            render={(props) => (
              <PrivacyPolicyPage
                appTitle={"Alexa Stats"}
                titleColor={"#4062BB"}
                effectiveDate={"12 February 2021"}
              ></PrivacyPolicyPage>
            )}
          />
          <Route
            path="/swiftlinks/about"
            exact
            render={(props) => <SwiftLinksPage></SwiftLinksPage>}
          />
          <Route
            path="/swiftlinks/privacy-policy"
            exact
            render={(props) => (
              <PrivacyPolicyPage
                appTitle={"SwiftLinks"}
                titleColor={"#81b29a"}
                effectiveDate={"19 October 2023"}
              ></PrivacyPolicyPage>
            )}
          />
          <Route
            path="/roundball/about"
            exact
            render={(props) => <RoundBallPage></RoundBallPage>}
          />
          <Route
            path="/roundball/privacy-policy"
            exact
            render={(props) => (
              <PrivacyPolicyPage
                appTitle={"RoundBall"}
                titleColor={"#1D3557"}
                effectiveDate={"31 October 2023"}
              ></PrivacyPolicyPage>
            )}
          />
          <Route
            path="/"
            exact
            render={(props) => <LandingPage></LandingPage>}
          />
          <Redirect to="/" />
        </Switch>
      </div>
    </Layout>
  );
}

export default withRouter(App);
