import React from "react";

import NavigationItems from "../NavigationItems/NavigationItems";
import DrawerToggle from "../SideDrawer/DrawerToggle/DrawerToggle";
import classes from "./Toolbar.module.scss";

const toolbar = (props) => (
  <nav
    className={`navbar navbar-custom navbar-expand-md navbar-dark  fixed-top justify-content-start`}
    style={{ maxWidth: "100% !important" }}
  >
    <div
      className={`container`}
      style={{ marginRight: "5px", marginLeft: "5px", width: "auto" }}
    >
      <div
        className={`collapse navbar-collapse ${classes.NavBar}`}
        id="navbarResponsive"
      >
        <ul className={`navbar-nav ml-auto`}>
          <NavigationItems></NavigationItems>
        </ul>
      </div>
      <DrawerToggle clicked={props.drawerToggleClicked}></DrawerToggle>
    </div>
  </nav>
);

export default toolbar;
