import React from "react";

import classes from "./ProductsSection.module.scss";

import beach from "../../assets/images/beach.png";
import Paper from "@material-ui/core/Paper";
import Image from "react-bootstrap/Image";
import mtntopLogo from "../../assets/images/mtn_top_blue_on_clear_no_words_thick.png";
import teachersNoteLogo from "../../assets/images/teachers_note_launch_transparent.png";
import holyMinuteLogo from "../../assets/images/holyminute_logo_no_bg.png";
import languageHuntLogo from "../../assets/images/language_hunt_logo.png";
import roundballLogo from "../../assets/images/roundball_logo.png";
import swiftlinksLogo from "../../assets/images/swiftlinks_logo_smaller.png";
import littleTimerLogo from "../../assets/images/littletimer_clearbg.png";
import studySquaresLogo from "../../assets/images/studysquares_logo_words.png";
import alexaStatsLogo from "../../assets/images/alexa_stats_logo_no_bg.png";
import clothingLogo from "../../assets/images/clothing_rack.png";

const ProductsSection = (props) => {
  const mtntopProduct = (
    <a
      key={1}
      style={{
        //width: 200,
        height: 200,
        //backgroundColor: "whitesmoke",
        // margin: 10,
        borderRadius: 6,
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
        marginBottom: 65,
        //margin: 8,
      }}
      className={`${classes.CaseStudyCard} col-sm-12 col-lg-4 col-md-6`}
      href={"https://harbourviewtechnologies.com/#/mtntop-platform/about"}
    >
      {" "}
      <div className={classes.LogoImage}>
        {/* <img src={logo} className={"img-fluid"} /> */}
        <Image src={mtntopLogo} fluid />
      </div>
    </a>
  );
  const teachersNoteProduct = (
    <a
      key={2}
      style={{
        //width: 200,
        height: 200,
        //backgroundColor: "whitesmoke",
        //margin: 10,
        borderRadius: 6,
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
        marginBottom: 65,
        //margin: 8,
      }}
      className={`${classes.CaseStudyCard} col-sm-12 col-lg-4 col-md-6`}
      href={"https://harbourviewtechnologies.com/#/mtntop-platform/about"}
    >
      {" "}
      <div className={classes.LogoImage}>
        {/* <img src={logo} className={"img-fluid"} /> */}
        <Image src={teachersNoteLogo} fluid />
      </div>
    </a>
  );
  const holyMinuteProduct = (
    <a
      key={3}
      style={{
        //width: 200,
        height: 200,
        //backgroundColor: "whitesmoke",
        //margin: 10,
        borderRadius: 6,
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
        marginBottom: 65,
        //margin: 8,
      }}
      href={"https://harbourviewtechnologies.com/#/mtntop-platform/about"}
      className={`${classes.CaseStudyCard} col-sm-12 col-lg-4 col-md-6`}
      // onClick={() => {
      //   // history.push(`/case-study/${CASE_STUDIES[0].id}`);
      //   console.log("clicked on mtntop");
      // }}
    >
      {" "}
      <div className={classes.LogoImage}>
        {/* <img src={logo} className={"img-fluid"} /> */}
        <Image src={holyMinuteLogo} fluid />
      </div>
    </a>
  );

  const languageHuntProduct = (
    <a
      key={4}
      style={{
        //width: 200,
        height: 200,
        //backgroundColor: "whitesmoke",
        //margin: 10,
        borderRadius: 6,
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
        marginBottom: 65,
        //margin: 8,
      }}
      href={"https://harbourviewtechnologies.com/#/languagehunt/about"}
      className={`${classes.CaseStudyCard} col-sm-12 col-lg-4 col-md-6`}
      // onClick={() => {
      //   // history.push(`/case-study/${CASE_STUDIES[0].id}`);
      //   console.log("clicked on mtntop");
      // }}
    >
      {" "}
      <div className={classes.LogoImage}>
        {/* <img src={logo} className={"img-fluid"} /> */}
        <Image src={languageHuntLogo} fluid />
      </div>
    </a>
  );

  const littleTimerProduct = (
    <a
      key={5}
      style={{
        //width: 200,
        height: 200,
        //backgroundColor: "whitesmoke",
        //margin: 10,
        borderRadius: 6,
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
        marginBottom: 65,
        //margin: 8,
      }}
      href={"https://harbourviewtechnologies.com/#/littletimer/about"}
      className={`${classes.CaseStudyCard} col-sm-12 col-lg-4 col-md-6`}
      // onClick={() => {
      //   // history.push(`/case-study/${CASE_STUDIES[0].id}`);
      //   console.log("clicked on mtntop");
      // }}
    >
      {" "}
      <div className={classes.LogoImage}>
        {/* <img src={logo} className={"img-fluid"} /> */}
        <Image src={littleTimerLogo} fluid />
      </div>
    </a>
  );

  const studySquaresProduct = (
    <a
      key={6}
      style={{
        //width: 200,
        height: 200,
        //backgroundColor: "whitesmoke",
        //margin: 10,
        borderRadius: 6,
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
        marginBottom: 65,
        //margin: 8,
      }}
      href={"https://harbourviewtechnologies.com/#/studysquares/about"}
      className={`${classes.CaseStudyCard} col-sm-12 col-lg-4 col-md-6`}
      // onClick={() => {
      //   // history.push(`/case-study/${CASE_STUDIES[0].id}`);
      //   console.log("clicked on mtntop");
      // }}
    >
      {" "}
      <div className={classes.SpanishLogoImage}>
        {/* <img src={logo} className={"img-fluid"} /> */}
        <Image src={studySquaresLogo} fluid />
      </div>
    </a>
  );

  const alexaStatsProduct = (
    <a
      key={7}
      style={{
        //width: 200,
        height: 200,
        //backgroundColor: "whitesmoke",
        //margin: 10,
        borderRadius: 6,
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
        marginBottom: 65,
        marginTop: 25,
        //margin: 8,
      }}
      href={"https://harbourviewtechnologies.com/#/alexastats/about"}
      className={`${classes.CaseStudyCard} col-sm-12 col-lg-4 col-md-6`}
      // onClick={() => {
      //   // history.push(`/case-study/${CASE_STUDIES[0].id}`);
      //   console.log("clicked on mtntop");
      // }}
    >
      {" "}
      <div className={classes.LogoImage}>
        {/* <img src={logo} className={"img-fluid"} /> */}
        <Image src={alexaStatsLogo} fluid />
      </div>
    </a>
  );

  const roundBallProduct = (
    <a
      key={4}
      style={{
        //width: 200,
        height: 200,
        //backgroundColor: "whitesmoke",
        //margin: 10,
        borderRadius: 6,
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
        marginBottom: 65,
        //margin: 8,
      }}
      href={"https://harbourviewtechnologies.com/#/roundball/about"}
      className={`${classes.CaseStudyCard} col-sm-12 col-lg-4 col-md-6`}
      // onClick={() => {
      //   // history.push(`/case-study/${CASE_STUDIES[0].id}`);
      //   console.log("clicked on mtntop");
      // }}
    >
      {" "}
      <div className={classes.RBLogoImage}>
        {/* <img src={logo} className={"img-fluid"} /> */}
        <Image src={roundballLogo} fluid className={classes.RoundCorners} />
      </div>
    </a>
  );

  const swiftLinksProduct = (
    <a
      key={4}
      style={{
        //width: 200,
        height: 200,
        //backgroundColor: "whitesmoke",
        //margin: 10,
        borderRadius: 6,
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
        marginBottom: 65,
        //margin: 8,
      }}
      href={"https://harbourviewtechnologies.com/#/swiftlinks/about"}
      className={`${classes.CaseStudyCard} col-sm-12 col-lg-4 col-md-6`}
      // onClick={() => {
      //   // history.push(`/case-study/${CASE_STUDIES[0].id}`);
      //   console.log("clicked on mtntop");
      // }}
    >
      {" "}
      <div className={classes.LogoImage}>
        {/* <img src={logo} className={"img-fluid"} /> */}
        <Image src={swiftlinksLogo} fluid />
      </div>
    </a>
  );

  const clothingProduct = (
    <a
      key={7}
      style={{
        width: 200,
        height: 200,
        //backgroundColor: "whitesmoke",
        //margin: 10,
        borderRadius: 6,
        alignItems: "center",
        justifyContent: "center",
        //display: "flex",
        marginBottom: 65,
        marginTop: 20,
        //margin: 8,
      }}
      href={"https://harbourviewtechnologies.com/#/design/clothing"}
      className={`${classes.CaseStudyCard} col-sm-12 col-lg-4 col-md-6`}
      // onClick={() => {
      //   // history.push(`/case-study/${CASE_STUDIES[0].id}`);
      //   console.log("clicked on mtntop");
      // }}
    >
      {" "}
      <div className={classes.LogoImage}>
        {/* <img src={logo} className={"img-fluid"} /> */}
        <Image
          src={clothingLogo}
          style={{
            maxWidth: 200,
            height: 200,
            borderRadius: 15,
            alignItems: "center",
            justifyContent: "center",
          }}
        />
      </div>
    </a>
  );

  const productsToShow = [
    swiftLinksProduct,
    studySquaresProduct,
    roundBallProduct,
    ,
    alexaStatsProduct,
  ];

  const hallOfFameProducts = [
    mtntopProduct,
    languageHuntProduct,
    holyMinuteProduct,
    littleTimerProduct,
    teachersNoteProduct,
  ];
  // Adding design
  // const designProductsToShow = [clothingProduct];

  return (
    <div
      style={{
        alignSelf: "center",
        justifyContent: "center",
        display: "flex",
        paddingTop: 35,
      }}
      id={props.id}
    >
      <div
        style={{
          fontWeight: "bold",
          fontSize: 34,
          color: "#444444",
          //backgroundColor: "whitesmoke",
          display: "flex",

          borderRadius: 5,
          margin: 10,
          marginBottom: 0,
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {/* <div className={`row ${classes.ResponsiveRow}`}>
          <div
            className={`col-sm-12 col-lg-12 col-md-12 ${classes.ResponsiveCol}`}
            style={{
              textAlign: "center",
              paddingTop: "30px",
            }}
          >
            <div className={`${classes.SectionTitle}`}>Design </div>
          </div>
        </div> */}
        {/* <div className={`row ${classes.ResponsiveRow}`}>
          <div
            className={`col-sm-12 col-lg-12 col-md-12`}
            style={{
              paddingTop: "30px",
            }}
          >
            {designProductsToShow}
          </div>
        </div> */}
        {/* <div className={`row ${classes.ResponsiveRow}`}>
          <div
            className={`col-sm-12 col-lg-12 col-md-12 ${classes.ResponsiveCol}`}
            style={{
              textAlign: "center",
              paddingTop: "30px",
            }}
          >
            <div className={`${classes.SectionTitle}`}>Software </div>
          </div>
        </div> */}
        {/* TOOK OUT THE BELOW FOR TSHIRTS ADDITION */}
        <div className={`row ${classes.ResponsiveRow}`}>
          <div
            className={`col-sm-12 col-lg-12 col-md-12 ${classes.ResponsiveCol}`}
            style={{
              textAlign: "center",
              paddingTop: "30px",
            }}
          >
            <div className={`${classes.SectionTitle}`}>
              Our{" "}
              <span style={{ color: "#81CBD2", fontWeight: "bold" }}>
                Products
              </span>
            </div>
            <div className={`${classes.ExplanatoryMessageFirst}`}>
              Currently available on the app store:
            </div>
          </div>
        </div>
        <div
          className={`row ${classes.ResponsiveRow}`}
          style={{ marginTop: 20 }}
        >
          {productsToShow}
        </div>
        <div className={`row ${classes.ResponsiveRow}`}>
          <div
            className={`col-sm-12 col-lg-12 col-md-12 ${classes.ResponsiveCol}`}
            style={{
              textAlign: "center",
              paddingTop: "30px",
            }}
          >
            <div className={`${classes.SectionTitle}`}>
              Hall of{" "}
              <span style={{ color: "#81CBD2", fontWeight: "bold" }}>Fame</span>
            </div>
            <div className={`${classes.ExplanatoryMessage}`}>
              At Harbour View, we try things. A lot of things. Our spirit of
              experimentation means that not every app we build lasts forever.
              Below are the greatest hits of what we have built in the past:
            </div>
          </div>
        </div>
        <div
          className={`row ${classes.ResponsiveRow}`}
          style={{ marginTop: 20 }}
        >
          {hallOfFameProducts}
        </div>
      </div>
    </div>
  );
};

export default ProductsSection;
