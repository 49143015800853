import React from "react";

import classes from "./DesignGallery.module.scss";

import beach from "../../assets/images/beach.png";
import Paper from "@material-ui/core/Paper";
import Image from "react-bootstrap/Image";
import shirt1Logo from "../../assets/images/shirt_1.png";
import shirt2Logo from "../../assets/images/shirt_2.png";
import shirt3Logo from "../../assets/images/shirt_3.png";
import languageHuntLogo from "../../assets/images/language_hunt_logo.png";
import littleTimerLogo from "../../assets/images/littletimer_clearbg.png";
import studySquaresLogo from "../../assets/images/studysquares_logo_words.png";
import alexaStatsLogo from "../../assets/images/alexa_stats_logo_no_bg.png";
import clothingLogo from "../../assets/images/clothing_rack.png";

const DesignGallery = (props) => {
  const musicNoteProduct = (
    <a
      key={1}
      style={{
        //width: 200,
        height: 200,
        //backgroundColor: "whitesmoke",
        // margin: 10,
        borderRadius: 6,
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
        marginBottom: 105,
        //margin: 8,
      }}
      className={`col-sm-12 col-lg-4 col-md-6`}
      //href={"https://mtntopshout.com"}
    >
      {" "}
      <div className={classes.LogoImage}>
        {/* <img src={logo} className={"img-fluid"} /> */}
        <Image src={shirt1Logo} fluid />
      </div>
    </a>
  );
  const signpostProduct = (
    <a
      key={2}
      style={{
        //width: 200,
        height: 300,
        //backgroundColor: "whitesmoke",
        //margin: 10,
        borderRadius: 6,
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
        marginBottom: 105,
        //margin: 8,
      }}
      className={`col-sm-12 col-lg-4 col-md-6`}
      //href={"https://myteachersnote.com"}
    >
      {" "}
      <div className={classes.LogoImage}>
        {/* <img src={logo} className={"img-fluid"} /> */}
        <Image src={shirt2Logo} fluid />
      </div>
    </a>
  );
  const soundwaveProduct = (
    <a
      key={3}
      style={{
        //width: 200,
        height: 300,
        //backgroundColor: "whitesmoke",
        //margin: 10,
        borderRadius: 6,
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
        marginBottom: 105,
        //margin: 8,
      }}
      //href={"https://holymin.com"}
      className={`col-sm-12 col-lg-4 col-md-6`}
      // onClick={() => {
      //   // history.push(`/case-study/${CASE_STUDIES[0].id}`);
      //   console.log("clicked on mtntop");
      // }}
    >
      {" "}
      <div className={classes.LogoImage}>
        {/* <img src={logo} className={"img-fluid"} /> */}
        <Image src={shirt3Logo} fluid />
      </div>
    </a>
  );

  const languageHuntProduct = (
    <a
      key={4}
      style={{
        //width: 200,
        height: 300,
        //backgroundColor: "whitesmoke",
        //margin: 10,
        borderRadius: 6,
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
        marginBottom: 65,
        //margin: 8,
      }}
      href={"https://harbourviewtechnologies.com/#/languagehunt/about"}
      className={`${classes.CaseStudyCard} col-sm-12 col-lg-4 col-md-6`}
      // onClick={() => {
      //   // history.push(`/case-study/${CASE_STUDIES[0].id}`);
      //   console.log("clicked on mtntop");
      // }}
    >
      {" "}
      <div className={classes.LogoImage}>
        {/* <img src={logo} className={"img-fluid"} /> */}
        <Image src={languageHuntLogo} fluid />
      </div>
    </a>
  );

  const littleTimerProduct = (
    <a
      key={5}
      style={{
        //width: 200,
        height: 200,
        //backgroundColor: "whitesmoke",
        //margin: 10,
        borderRadius: 6,
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
        marginBottom: 65,
        //margin: 8,
      }}
      href={"https://harbourviewtechnologies.com/#/littletimer/about"}
      className={`${classes.CaseStudyCard} col-sm-12 col-lg-4 col-md-6`}
      // onClick={() => {
      //   // history.push(`/case-study/${CASE_STUDIES[0].id}`);
      //   console.log("clicked on mtntop");
      // }}
    >
      {" "}
      <div className={classes.LogoImage}>
        {/* <img src={logo} className={"img-fluid"} /> */}
        <Image src={littleTimerLogo} fluid />
      </div>
    </a>
  );

  const studySquaresProduct = (
    <a
      key={6}
      style={{
        //width: 200,
        height: 200,
        //backgroundColor: "whitesmoke",
        //margin: 10,
        borderRadius: 6,
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
        marginBottom: 65,
        //margin: 8,
      }}
      href={"https://harbourviewtechnologies.com/#/studysquares/about"}
      className={`${classes.CaseStudyCard} col-sm-12 col-lg-4 col-md-6`}
      // onClick={() => {
      //   // history.push(`/case-study/${CASE_STUDIES[0].id}`);
      //   console.log("clicked on mtntop");
      // }}
    >
      {" "}
      <div className={classes.LogoImage}>
        {/* <img src={logo} className={"img-fluid"} /> */}
        <Image src={studySquaresLogo} fluid />
      </div>
    </a>
  );

  const alexaStatsProduct = (
    <a
      key={7}
      style={{
        //width: 200,
        height: 200,
        //backgroundColor: "whitesmoke",
        //margin: 10,
        borderRadius: 6,
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
        marginBottom: 65,
        //margin: 8,
      }}
      href={"https://harbourviewtechnologies.com/#/alexastats/about"}
      className={`${classes.CaseStudyCard} col-sm-12 col-lg-4 col-md-6`}
      // onClick={() => {
      //   // history.push(`/case-study/${CASE_STUDIES[0].id}`);
      //   console.log("clicked on mtntop");
      // }}
    >
      {" "}
      <div className={classes.LogoImage}>
        {/* <img src={logo} className={"img-fluid"} /> */}
        <Image src={alexaStatsLogo} fluid />
      </div>
    </a>
  );

  const clothingProduct = (
    <a
      key={7}
      style={{
        width: 200,
        height: 200,
        //backgroundColor: "whitesmoke",
        //margin: 10,
        borderRadius: 6,
        alignItems: "center",
        justifyContent: "center",
        //display: "flex",
        marginBottom: 65,
        marginTop: 20,
        //margin: 8,
      }}
      href={"https://harbourviewtechnologies.com/#/alexastats/about"}
      className={`${classes.CaseStudyCard} col-sm-12 col-lg-4 col-md-6`}
      // onClick={() => {
      //   // history.push(`/case-study/${CASE_STUDIES[0].id}`);
      //   console.log("clicked on mtntop");
      // }}
    >
      {" "}
      <div className={classes.LogoImage}>
        {/* <img src={logo} className={"img-fluid"} /> */}
        <Image
          src={clothingLogo}
          style={{
            maxWidth: 200,
            height: 200,
            borderRadius: 15,
            alignItems: "center",
            justifyContent: "center",
          }}
        />
      </div>
    </a>
  );

  const productsToShow = [musicNoteProduct, signpostProduct, soundwaveProduct];

  return (
    <div
      style={{
        alignSelf: "center",
        justifyContent: "center",
        display: "flex",
        paddingTop: 35,
      }}
      id={props.id}
    >
      <div
        style={{
          fontWeight: "bold",
          fontSize: 34,
          color: "#444444",
          //backgroundColor: "whitesmoke",
          display: "flex",

          borderRadius: 5,
          margin: 10,
          marginBottom: 0,
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div className={`row ${classes.ResponsiveRow}`}>
          <div
            className={`col-sm-12 col-lg-12 col-md-12 ${classes.ResponsiveCol}`}
            style={{
              textAlign: "center",
              paddingTop: "30px",
              marginBottom: "70px",
            }}
          >
            <div className={`${classes.SectionTitle}`}>Clothing Design </div>
          </div>
        </div>
        {/* TOOK OUT THE BELOW FOR TSHIRTS ADDITION */}
        {/* <div className={`row ${classes.ResponsiveRow}`}>
          <div
            className={`col-sm-12 col-lg-12 col-md-12 ${classes.ResponsiveCol}`}
            style={{
              textAlign: "center",
              paddingTop: "30px",
            }}
          >
            <div className={`${classes.SectionTitle}`}>
              Our{" "}
              <span style={{ color: "#81CBD2", fontWeight: "bold" }}>
                Products
              </span>
            </div>
          </div>
        </div> */}
        <div
          className={`row ${classes.ResponsiveRow}`}
          style={{ marginTop: 20 }}
        >
          {productsToShow}
        </div>
      </div>
    </div>
  );
};

export default DesignGallery;
