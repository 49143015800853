import React from "react";

import { NavHashLink as NavLink } from "react-router-hash-link";

import classes from "./NavigationItems.module.scss";

const TOOLBAR_CONFIG = [
  {
    title: "Home",
    path: "/",
    hash: "#home",
  },
  {
    title: "About",
    path: "/",
    hash: "#about",
  },
  {
    title: "Products",
    path: "/",
    hash: "#products",
  },

  {
    title: "Contact",
    path: "/",
    hash: "#contact",
  },
];

const navigationItems = (props) => {
  const navList = TOOLBAR_CONFIG.map((toolbarItem, index) => {
    return (
      <NavLink
        key={index}
        to={{ pathname: toolbarItem.path, hash: toolbarItem.hash }}
        className={`nav-item ${classes.NavItemContainer}`}
        activeClassName={`active ${classes.ActiveLink}`}
        isActive={(match, location) => {
          if (location.hash || toolbarItem.hash) {
            return (
              location.hash === toolbarItem.hash ||
              (!location.hash && toolbarItem.hash === "#home")
            );
          } else {
            return location.pathname === toolbarItem.path;
          }
        }}
      >
        <span className={`nav-link ${classes.NavLink}`}>
          {toolbarItem.title}
        </span>
      </NavLink>
    );
  });

  return navList;
};

export default navigationItems;
