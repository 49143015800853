import React from "react";

import Banner from "../../components/Banner/Banner";
import PictureWithParagraph from "../../components/PictureWithParagraph/PictureWithParagraph";
import MissionStatementSection from "../../components/MissionStatementSection/MissionStatementSection";
import ProductsSection from "../../components/ProductsSection/ProductsSection";
import ContactSection from "../../components/ContactSection/ContactSection";

import classes from "./LandingPage.module.scss";

const landingPage = (props) => {
  return (
    <React.Fragment>
      {/* <Banner id="home"></Banner> */}

      {/* <PictureWithParagraph></PictureWithParagraph> */}
      <div className="site-background" style={{ zIndex: 20 }}>
        <Banner id="home"></Banner>
      </div>
      <MissionStatementSection id="about"></MissionStatementSection>
      <ProductsSection id="products"></ProductsSection>
      <ContactSection id="contact"></ContactSection>
    </React.Fragment>
  );
};

export default landingPage;
